import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import DataContext from '../context/data-context';

const Notification = ({ text, link = '', name, beforeText = '', useLocalStorage = true }) => {
  const {
    breadcrumb,
  } = useContext(DataContext);
  const [showNotification, setShowNotification] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined' && useLocalStorage) {
      const storedValue = sessionStorage.getItem(name);
      if (storedValue) {
        setShowNotification(JSON.parse(storedValue));
      }
    }
  }, []);


  useEffect(() => {
    if (!useLocalStorage) {
      setShowNotification(true);
    }
  }, [breadcrumb]);

  if (!showNotification) {
    return null;
  }

  const handleClick = () => {
    setShowNotification(false);

    if (useLocalStorage) {
      sessionStorage.setItem(name, JSON.stringify(false));
    }
  };

  const renderLinesWithBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  return (
    <div className={`notification notification--${name}`}>
      <div className="outer-padding">
        <div className="notification-container">
          <div className="notification-content-left">
            <i className="icon icon-warning-2" />
            {link ?  (
              <p className="notification-text">
                {renderLinesWithBreaks(beforeText)}
                <a href={link} target='_blank'>
                  <p>{text}</p>
                </a>
              </p>
            ) : (
              <p className="notification-text">
                <span> {renderLinesWithBreaks(text)}</span>
              </p>
            )}
          </div>
          <div className="notification-content-right">
            <button className="icon-cross" onClick={handleClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  name: PropTypes.string.isRequired,
  beforeText: PropTypes.string,
  useLocalStorage: PropTypes.bool,
};

Notification.defaultProps = {
  text: '',
  link: '',
  name: '',
  useLocalStorage: true,
};

export default Notification;
