/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import { Link } from 'gatsby';
import LayoutContext from '../context/layout-context';
import DataContext from '../context/data-context';
import NavigationFilter from './navigation-filter';

// url, children,isPage, title isDir

const LeftNavigation = ({ location }) => {
  const hiddenProducts = [
    'continia finance',
    'continia banking',
    'continia sustainability',
    'continia opplus smart banking',
    'continia e-documents export',
    'continia e-documents import',
  ];
  const { mainTop, mainBottom, mobileLeftNavOpen, setMobileLeftNavOpen } =
    useContext(LayoutContext);

  const {
    toc,
    path,
    pageType,
    product,
    settings: { dictionary, prefix },
  } = useContext(DataContext);

  const is404Page = pageType === '404';
  const referedPath =
    (location &&
      location.search &&
      location.search.substr(1).split('=')[0] === 'path' &&
      location.search.split('=')[1]) ||
    '';

  const referedPathParts = referedPath
    ? referedPath
        .split('/')
        .slice(0, 3)
        .filter((item) => !!item)
    : [];
  const [ref, { width }] = useMeasure();

  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    const defaultExpanded = {};

    const checkLevel = (children) => {
      children.forEach((item) => {
        if (path === item.url || path.startsWith(item.url + '/')) {
          defaultExpanded[item.url] = true;

          if (item.children) {
            checkLevel(item.children);
          }
        }
      });
    };

    checkLevel(toc || []);
    setExpanded(defaultExpanded);
  }, [path, toc]);

  const renderChildren = (children) =>
    (children || []).map((item) => {
      if (!item.isDir) {
        return (
          <li key={`file-${item.url}`}>
            <Link
              className={`tree-item is-leaf ${
                item.url === path ||
                `/${prefix}${item.url}` === path ||
                item.url === `/${prefix}${path}`
                  ? 'selected'
                  : ''
              }`}
              to={item.url}
              onClick={() => setMobileLeftNavOpen(false)}
            >
              {item.title}
            </Link>
          </li>
        );
      }
      if (item.isDir) {
        const isExpanded = expanded[item.url];

        return (
          <li
            key={`folder-${item.url}`}
            className={`tree-item ${isExpanded ? 'is-expanded' : ''}`}
          >
            <span
              role="button"
              tabIndex={-1}
              className="tree-expander"
              onClick={() => {
                setExpanded({
                  ...expanded,
                  [item.url]: !isExpanded,
                });
              }}
            >
              <i className="icon icon-chevron-right" />
              {item.title}
            </span>
            {item.children.length > 0 ? (
              <ul className="tree-group">
                {renderChildren(item.children || [])}
              </ul>
            ) : null}
          </li>
        );
      }
      return null;
    });

  let firstLevel = toc.filter(
    (item) =>
      item.url &&
      !item.url.toLowerCase().startsWith('/en-us/legal') &&
      !item.url.toLowerCase().startsWith('/legal') &&
      !hiddenProducts.includes(item.title.toLowerCase())
  );

  if (is404Page && referedPathParts.length) {
    if (
      firstLevel.find((item) => referedPathParts.includes(item.url.substr(1)))
    ) {
      firstLevel = firstLevel.find((item) =>
        referedPathParts.includes(item.url.substr(1))
      ).children;
    } else {
      return null;
    }
  }

  if (product === 'all' && !firstLevel?.length) {
    return null;
  }

  return (
    <div
      className={`left-navigation ${mainTop === null ? 'invisible' : ''} ${
        mobileLeftNavOpen ? 'open-mobile' : ''
      }`}
    >
      <div
        id="left-navigation-inner"
        className="left-navigation-inner"
        ref={ref}
      >
        <div className="mobile-header">
          <h3>{dictionary.navigationMobileContentHeader}</h3>
          <button
            type="button"
            onClick={() => {
              setMobileLeftNavOpen(false);
            }}
          >
            <span />
            <span />
          </button>
        </div>
        <nav
          id="left-navigation-fixed"
          style={{
            width,
            top: mainTop + 24,
            bottom: mainBottom + 24,
          }}
        >
          <NavigationFilter product={product} />
          <ul className="tree">{renderChildren(firstLevel)}</ul>
        </nav>
      </div>
    </div>
  );
};

LeftNavigation.propTypes = {
  location: PropTypes.object,
};
LeftNavigation.defaultProps = {};

export default LeftNavigation;
